/* 超出显示省略号等这些base style */
@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}