@import url('./reset.css');
@import url('./base.css');

html, body {
  font-size: 16px;
}


@media (min-width: 568px) {
  .wrap {
    width: 540px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .wrap {
    width: 720px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1200px) {
  .wrap {
    width: 1200px;
    margin: 0 auto;
  }
}

