* {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: bottom;
}

ul, ol, dl {
  list-style: none;
}

a {
  text-decoration: none;
}

input {
  outline: none;
}